import { BiSolidDonateHeart } from 'react-icons/bi';
import {
  BookOpenIcon,
  BriefcaseIcon,
  CurrencyDollarIcon,
  DocumentIcon,
  HomeIcon,
  SparklesIcon,
  Square2StackIcon,
  UsersIcon
} from '@heroicons/react/20/solid';
import { NavItem } from 'components/layout/types';

export const navConfig: { [key: string]: NavItem[] } = {
  default: [
    //need to change adoption pages back to /waiting-kids-[statecode]/
    {
      name: 'Adoption',
      path: '/waiting-kids/mn/',
      children: [
        {
          subItemName: 'MN Waiting Kids',
          path: '/waiting-kids/[statecode]/',
          as: '/waiting-kids/mn/'
        },
        {
          subItemName: 'WI Waiting Kids',
          path: '/waiting-kids/[statecode]/',
          as: '/waiting-kids/wi/'
        },
        {
          subItemName: 'ND Waiting Kids',
          path: '/waiting-kids/[statecode]/',
          as: '/waiting-kids/nd/'
        },
        {
          subItemName: 'IA Waiting Kids',
          path: '/waiting-kids/[statecode]/',
          as: '/waiting-kids/ia/'
        },
        {
          subItemName: 'WY Waiting Kids',
          path: '/waiting-kids/[statecode]/',
          as: '/waiting-kids/wy/'
        },
        {
          subItemName: 'ID Waiting Kids',
          path: '/waiting-kids/[statecode]/',
          as: '/waiting-kids/id/'
        },
        {
          subItemName: 'CO Waiting Kids',
          path: '/waiting-kids/[statecode]/',
          as: '/waiting-kids/co/'
        },
        {
          subItemName: 'NV Waiting Kids',
          path: '/waiting-kids/[statecode]/',
          as: '/waiting-kids/nv/'
        },
        {
          subItemName: 'Forever Family Follow-Up Stories',
          path: '/forever-family-2/'
        }
      ]
    },

    {
      name: 'Upstream',
      path: '/upstream-stories/',
      children: [
        {
          subItemName: 'Prevent',
          path: '/upstream-stories-prevent/'
        },
        {
          subItemName: 'Foster',
          path: '/upstream-stories-foster/'
        },
        {
          subItemName: 'Wrap-Around',
          path: '/upstream-stories-wrap-around/'
        }
      ]
    },
    {
      name: 'How It Works',
      path: '/how-it-works/',
      children: [
        {
          subItemName: 'Social Workers',
          path: '/refer-a-child/',
          subKey: 'social-workers',
          children: [
            {
              subItemName: 'Refer A Child',
              path: '/refer-a-child/'
            },
            {
              subItemName: 'Working With Our Team',
              path: '/how-it-works/'
            },
            {
              subItemName: 'Saying Yes to Family',
              path: '/saying-yes-to-family/'
            }
          ]
        },
        {
          subItemName: 'Foster Parents / Group Home Staff',
          path: 'https://www.canva.com/design/DAFcdbq8Zkw/PqdjuSU75lLOHi08Pg2H-g/view?utm_content=DAFcdbq8Zkw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink'
        },
        {
          subItemName: 'Prospective Adoptive Parents',
          path: '/get-started/'
        },
        {
          subItemName: 'Kids + Teens',
          path: '/saying-yes-to-family/',
          subKey: 'kids-teens',
          children: [
            {
              subItemName: 'Saying Yes to Family',
              path: '/saying-yes-to-family/'
            },
            {
              subItemName: 'Meet Our Film Crew',
              path: '/film-crew/'
            }
          ]
        },
        {
          subItemName: 'Pastors + Ministry Leaders',
          path: '/pastors-and-leaders/',
          subKey: 'pastors-leaders',
          children: [
            {
              subItemName: 'Team Up With Us!',
              path: '/pastors-and-leaders/'
            },
            {
              subItemName: 'Resource Videos',
              path: '/resource-videos/'
            }
          ]
        }
      ]
    },
    {
      name: 'About',
      path: '/about-us/',
      children: [
        {
          subItemName: 'About Us',
          path: '/about-us/'
        },
        {
          subItemName: 'FAQ',
          path: '/faq/'
        },
        {
          subItemName: 'Meet Our Team',
          path: '/meet-our-team/'
        },
        {
          subItemName: 'Meet Our Supporters',
          path: '/our-supporters/'
        },
        {
          subItemName: 'Media',
          path: '/media/'
        }
      ]
    },
    {
      name: 'Get Involved',
      path: '/events/',
      children: [
        {
          subItemName: 'Events',
          path: '/events/'
        },
        {
          subItemName: 'Join Our Team',
          path: '/join-our-team/'
        },
        {
          subItemName: 'Contact Us',
          path: '/contact-us/'
        }
      ]
    }
  ],
  user: [
    {
      name: 'Dashboard',
      path: '/dashboard/'
    },
    {
      name: 'Support',
      path: '/support'
    },
    {
      name: 'My Account',
      path: '/account'
    }
  ],
  admin: [
    {
      name: 'Dashboard',
      path: '/admin/',
      icon: HomeIcon
    },
    {
      name: 'Admin Users',
      path: '/admin/users/',
      icon: UsersIcon
    },
    {
      name: 'Pages',
      path: '/admin/pages/',
      icon: BookOpenIcon
    },
    {
      name: 'Form Submissions',
      path: '/admin/submissions/',
      icon: DocumentIcon
    },
    {
      name: 'Donations',
      path: '/admin/donations/',
      icon: CurrencyDollarIcon
    },
    {
      name: 'Adoption Pages',
      path: '/admin/adoption-pages/',
      icon: Square2StackIcon
    },
    {
      name: 'Give Campaigns',
      path: '/admin/give-campaigns/',
      icon: BiSolidDonateHeart
    },
    {
      name: 'Job Listings',
      path: '/admin/job-listings/',
      icon: BriefcaseIcon
    },
    {
      name: 'Events',
      path: '/admin/events/',
      icon: SparklesIcon
    }
  ]
};
